import { getAuth } from 'firebase/auth';

let currentUser = null;

const setCurrentUser = (user) => {
  currentUser = user;
};
const getCurrentUser = () => currentUser;

const getAuthToken = () => {
  const user = getCurrentUser();
  return user ? user.accessToken : null;
};

const isSecured = () => !!getAuthToken();

const logOut = () => getAuth().signOut();

export { setCurrentUser, getCurrentUser, getAuthToken, isSecured, logOut };
