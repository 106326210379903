import React from 'react';
import { Navigate } from 'react-router-dom';

import { SecuredContext } from 'core/auth/secured';

const LandingPage = () => {
  const { userDetails } = React.useContext(SecuredContext);
  return <Navigate to="/devices" />;
};

export { LandingPage };
