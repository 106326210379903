import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import { setCurrentUser } from 'core/auth/auth-utils';
import { AppLoader } from 'components/app-loader';
import { Main } from 'components/main';
import { apiClient } from 'core/api/api-client';

const SecuredContext = React.createContext();

const Secured = ({ children }) => {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [user, loading, error] = useAuthState(getAuth());

  useEffect(() => {
    if (user) {
      setIsApiLoading(true);
      setCurrentUser(user);
      const newUserDetails = {
        userUID: user.uid,
        phoneNumber: user.phoneNumber,
      };
      apiClient
        .getUserDetails(user.uid)
        // Promise.all([apiClient.getUserDetails(user.uid), apiClient.getActiveCard(user.uid)])
        // .then((responses) => {
        // })
        .then((response) => {
          newUserDetails.username = response.username;
          newUserDetails.firstName = response.firstName;
          newUserDetails.lastName = response.lastName;
          newUserDetails.email = response.email;
          newUserDetails.street = response.street;
          newUserDetails.country = response.country;
          newUserDetails.activeCard = response.cards[0];
        })
        .catch((error) => {
          console.log(error.status !== 404 ? `Unexpected error retrieving user data: ${error}` : error);
        })
        .finally(() => {
          setUserDetails(newUserDetails);
          setIsApiLoading(false);
        });
    }
  }, [user]);

  const isLoading = loading || isApiLoading;

  return isLoading || error ? (
    <AppLoader error={error} />
  ) : (
    <SecuredContext.Provider
      value={{ userDetails, setUserDetails: (newUserDetails) => setUserDetails(newUserDetails) }}>
      {user ? <Main>{children}</Main> : <Navigate to="/login" />}
    </SecuredContext.Provider>
  );
};

Secured.propTypes = {
  children: PropTypes.node,
};

export { Secured, SecuredContext };
