import React, { useState } from 'react';
import { Card, Form, Button, Modal, Spinner } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { apiClient } from 'core/api/api-client';

//FIXME: create reusable component
const circleStyle = {
  paddingTop: '10px',
  paddingLeft: '10px',
  height: '2rem',
  width: '2rem',
  verticalAlign: 'middle',
};

const Activity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActivityDetailsVisible, setIsActivityDetailsVisible] = useState(false);
  const [activityDetails, setActivityDetails] = useState({});
  const [searchText, setSearchText] = useState('');
  const [activityData, setActivityData] = useState([]);

  const showActivityDetails = (activityDetails) => {
    console.log(activityDetails);
    setActivityDetails(activityDetails);
    setIsActivityDetailsVisible(true);
  };

  const formatMobileRow = (data) => {
    const dateTime = DateTime.fromISO(data.date);
    const isMoneySent = data.amount > 0;
    return (
      <li className="list-group-item container-fluid" key={`activity-entry-${data.date}`}>
        <div className="row align-items-center" onClick={() => showActivityDetails(data)}>
          <div className="col-2 ps-0">
            <div className="text-center">
              <div className="small">{dateTime.toFormat('MMM')}</div>
              <div className="h4 fw-normal mb-0">{dateTime.toFormat('dd')}</div>
            </div>
          </div>
          <div className="col-5 small">
            <div className="text-truncate">{data.displayName || data.phone}</div>
            <div className="text-muted text-truncate">{data.concept || (isMoneySent ? 'sent' : 'received')}</div>
          </div>
          <div className="col-5 pe-2 text-end">
            <div className={`fw-bold ${isMoneySent ? 'text-secondary' : 'text-primary'}`}>{data.amount}</div>
            <div className="text-muted">XOFML</div>
          </div>
        </div>
        {/* <div className="d-flex flex-row align-items-center">
          <div className="text-center" style={{ width: '2rem' }}>
            <div className="small">{dateTime.toFormat('MMM')}</div>
            <div className="h4 fw-normal mb-0">{dateTime.toFormat('dd')}</div>
          </div>
          <div className="px-3 small" style={{ width: '75%' }}>
            <div className="text-truncate">{data.displayName || data.phone}</div>
            <div className="text-muted text-truncate">{data.concept || (isMoneySent ? 'sent' : 'received')}</div>
          </div>
          <div className="text-end" style={{ width: '20%' }}>
            <div className={`fw-bold ${isMoneySent ? 'text-secondary' : 'text-primary'}`}>{data.amount}</div>
            <div className="text-muted">XOFML</div>
          </div>
        </div> */}
      </li>
    );
  };

  const formatActivityDetails = (data) => {
    const dateTime = DateTime.fromISO(data.date);
    const isMoneySent = data.amount > 0;
    return (
      <div className="small">
        <p>
          <strong>Date: </strong> {dateTime.toFormat('DDD')}
        </p>
        <p>
          <strong>Recipient: </strong> {data.recipientFullName ? `${data.recipientFullName} (${data.recipientPhone})` : data.recipientPhone}
        </p>
        <p>
          <strong>Amount: </strong>
          <span className="text-muted">XOFML </span>
          <span className={`fw-bold ${isMoneySent ? 'text-secondary' : 'text-primary'}`}>{data.amount}</span>
        </p>
        {data.concept && (
          <p>
            <strong>Concept: </strong>
            {data.concept}
          </p>
        )}
      </div>
    );
  };

  const doSearch = (newSearchText) => {
    setIsLoading(true);
    apiClient
      .getActivityData(newSearchText)
      .then((response) => setActivityData(response))
      .finally(() => setIsLoading(false));
  };

  useEffect(doSearch, []);

  return (
    <div id="activity" className="d-flex flex-column flex-grow-1">
      {isLoading ? (
        <Spinner animation="border" role="status" className="text-primary gp-icon flex-shrink-0 m-auto">
          <span className="visually-hidden">Loading Activity Data...</span>
        </Spinner>
      ) : (
        <Form onSubmit={() => doSearch(searchText)}>
          <Card className="rounded-gp border-0 d-flex flex-grow-1 bg-light p-md-3">
            <Card.Header className="bg-transparent border-0 d-none d-md-block">
              <span className="display-5">Activity</span>
            </Card.Header>
            <Card.Body className="d-flex flex-column flex-grow-1">
              <div className="position-relative">
                <Form.Control
                  className="rounded-pill"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  variant="link"
                  className="position-absolute end-0"
                  style={{ bottom: '2px' }}
                  onClick={() => doSearch()}>
                  <FiSearch />
                </Button>
              </div>
              <div className="mt-3 mt-md-4 d-flex flex-column flex-grow-1 overflow-auto">
                <ul className="activity-list d-block d-md-none list-group list-group-flush">
                  {activityData.length > 0 ? (
                    activityData.map((data) => formatMobileRow(data))
                  ) : (
                    <div className="text-center">No activity data</div>
                  )}
                </ul>
                <table className="table d-none d-md-table">
                  <thead>
                    <tr>
                      <th scope="col">Recipient</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityData.map((data) => (
                      <tr key={`activity-entry-${data.date}`}>
                        <td>
                          <span className="badge bg-primary me-2 rounded-circle" style={circleStyle}>
                            {data.recipientFullName && data.recipientFullName.charAt(0)}
                          </span>
                          {data.recipientFullName} (<strong>{data.recipientPhone}</strong>)
                        </td>
                        <td>CFA {data.amount}</td>
                        <td>{data.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
          <Modal show={isActivityDetailsVisible} centered backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>{formatActivityDetails(activityDetails)}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="rounded-pill text-white px-5 m-auto"
                onClick={() => setIsActivityDetailsVisible(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </div>
  );
};

export { Activity };
