import React, { useState, useRef } from 'react';
import { signInWithPhoneNumber, getAuth, RecaptchaVerifier } from '@firebase/auth';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { ReactComponent as GopayLogo } from 'assets/gopay-logo.svg';
import { MyIntlTelInput } from 'components/my-intl-tel-input';

const Login = () => {
  const captchaRef = useRef(null);
  const [loginResult, setLoginResult] = useState();
  const [phone, setPhone] = useState();
  const [code, setCode] = useState();
  // initial -> ready-to-request -> requesting -> requested | request-failed -> entered -> verifying [-> verification-failed]
  const [loginState, setLoginState] = useState('initial');
  const [errorCode, setErrorCode] = useState();
  const navigate = useNavigate();

  const requestCode = (event) => {
    setLoginState('requesting');
    event.preventDefault();
    const auth = getAuth();
    const captchaVerifier = new RecaptchaVerifier(
      captchaRef.current,
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber
          console.log(response);
        },
      },
      auth
    );
    signInWithPhoneNumber(auth, phone, captchaVerifier)
      .then((result) => {
        setLoginResult(result);
        setLoginState('requested');
      })
      .catch((error) => {
        setLoginState('request-failed');
        console.log(error);
      });
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    newCode?.trim() !== '' ? setLoginState('entered') : setLoginState('requested');
  };

  const doLogin = (event) => {
    event.preventDefault();
    setLoginState('verifying');
    loginResult
      .confirm(code)
      .then(() => {
        navigate('/devices');
      })
      .catch((error) => {
        setLoginState('verification-failed');
        setErrorCode(error.code);
        console.log(error);
      });
  };

  const onPhoneChange = (isValid, value, selectedCountryData, fullNumber, extension) => {
    if (isValid) {
      setPhone(fullNumber);
      setLoginState('ready-to-request');
    } else setLoginState('initial');
  };

  const contactUs = () => {
    console.log(errorCode);
    return;
  };

  let requestActions;
  let verificationActions;
  switch (loginState) {
    //
    case 'initial':
      break;
    case 'ready-to-request':
      requestActions = (
        <Button
          variant="outline-light"
          className={`w-100 rounded-pill ${phone ? '' : 'invisible'}`}
          onClick={requestCode}>
          Request Code
        </Button>
      );
      break;
    case 'requesting':
      break;
    case 'request-failed':
      requestActions = (
        <p className="mt-3 text-light text-center">
          Your request failed, please try again with a different phone number or{' '}
          <Button variant="link" className="text-light p-0 align-baseline" onClick={contactUs}>
            contact us for help
          </Button>
        </p>
      );
      break;
    case 'requested':
      verificationActions = (
        <p className="text-light text-center mb-0">
          Haven't received your code yet?
          <Button variant="link" className="text-light p-0" onClick={() => setLoginState('initial')}>
            Request a new code
          </Button>
        </p>
      );
      break;
    case 'entered':
      verificationActions = (
        <Button variant="outline-light" className="w-100 rounded-pill" onClick={doLogin}>
          Sign In
        </Button>
      );
      break;
    case 'verifying':
      break;
    case 'verification-failed':
      verificationActions = (
        <p className="mt-3 text-light text-center">
          Incorrect or expired code, please try again or{' '}
          <Button variant="link" className="text-light p-0 align-baseline" onClick={contactUs}>
            Contact us
          </Button>{' '}
          for help
        </p>
      );
      break;
    default:
  }

  return (
    <div id="login" className="h-100 w-100 d-flex bg-gp-gradient">
      <div id="recaptcha-container" ref={captchaRef}></div>
      <div className="container d-flex flex-column flex-grow-1 justify-content-center">
        <div className="row">
          <div className="col-10 offset-1 col-lg-4 offset-lg-4 p-4 login-content">
            <h1 className="text-light text-center mb-4 pb-2 display-6">
              Sign in to <GopayLogo height="40" fill="white" />
            </h1>
            {loginState === 'initial' || loginState === 'ready-to-request' || loginState === 'request-failed' ? (
              <Form autoComplete="off" autoCorrect="off" autoCapitalize="none" noValidate onSubmit={requestCode}>
                <Form.Group className="text-center mb-4">
                    <MyIntlTelInput onPhoneChangeCallBack={onPhoneChange}/>
                </Form.Group>
                {requestActions}
              </Form>
            ) : loginState === 'requesting' || loginState === 'verifying' ? (
              <div className="text-center">
                <Spinner animation="border" role="status" className="text-light gp-icon flex-shrink-0" />
              </div>
            ) : (
              <Form autoComplete="off" autoCorrect="off" autoCapitalize="none" noValidate onSubmit={doLogin}>
                <div className="position-relative">
                  <Button
                    variant="invisible text-primary"
                    className="d-flex align-items-center p-0 position-absolute h-100 ms-2">
                    <FiArrowLeft />
                  </Button>
                  <Form.Control
                    className="text-center mb-4"
                    type="password"
                    name="code"
                    placeholder="Verification Code"
                    maxLength={15}
                    onChange={(e) => handleCodeChange(e.target.value)}
                  />
                </div>
                {verificationActions}
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="fixed-bottom">
        {loginState !== 'initial' && loginState !== 'ready-to-request' && (
          <div className="recaptcha-reference text-light fw-lighter mx-2 text-center mb-2">
            <p className="m-0">This site is protected by reCAPTCHA and the</p>
            <p className="m-0">
              Google&nbsp;
              <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;
              <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export { Login };
