import { getAuthToken } from 'core/auth/auth-utils';

const performRequest = (method, url, queryParams, payload, responseInterceptors) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const authToken = getAuthToken();
  authToken && headers.append('Authorization', `Bearer ${authToken}`);
  const request = payload ? { headers, method, body: JSON.stringify(payload) } : { headers, method };
  const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return fetch(url + queryString, request).then((response) => {
    const _response = responseInterceptors.reduce((acc, curr) => {
      acc = curr(acc);
      return acc;
    }, response);
    return _response.ok ? response.json().catch(() => {}) : Promise.reject(response);
  });
};

class HttpClient {
  constructor() {
    this._responseInterceptors = [];
  }

  setAuthToken(authToken) {
    this._authToken = authToken;
  }

  get(url) {
    return performRequest('GET', url, undefined, undefined, this._responseInterceptors);
  }

  post(url, payload) {
    return performRequest('POST', url, undefined, payload, this._responseInterceptors);
  }

  put(url, payload) {
    return performRequest('PUT', url, undefined, payload, this._responseInterceptors);
  }

  addResponseInterceptor(interceptor) {
    this._responseInterceptors.push(interceptor);
  }
}

const httpClient = new HttpClient();

export { httpClient, HttpClient };
