import React, { useState } from 'react';
import { Card, Form, Button, InputGroup  } from 'react-bootstrap';
import Map from 'modules/map/map';

import { SecuredContext } from 'core/auth/secured';
import { apiClient } from 'core/api/api-client';
import { useEffect } from 'react';

const defaultLocation = [
  {
    address: 'Device 1',
    geoPoint: {
      latitude: 39.758291,
      longitude: -104.935547,
    }
  }
]

const Devices = () => {
  const { userDetails } = React.useContext(SecuredContext);
  const [devicesData, setDevicesData] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [locations, setLocations] = useState(defaultLocation);
  const [isLoading, setIsLoading] = useState(false);


  const searchDevices = () => {
    setIsLoading(true);
    apiClient
      .getDevices(userDetails.userUID)
      .then((response) => {
        setDevicesData(response);
        const ids = response.map((device, i) => device.deviceId);
        setSelectedDeviceIds(ids);
        return ids;
      }).then((ids)=> fetchLoactions(ids))
      .finally(() => setIsLoading(false));
  };

  const fetchLoactions = (ids) => {
    setIsLoading(true);
    apiClient
      .getActivities(ids)
      //TODO this should override defaultLoaction
      .then((response) => setLocations(response))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    searchDevices();
  }, []);

  const handleDeviceSelection = event => { 
    if (event.target.checked)
    {
      selectedDeviceIds.push(event.target.id);
    } else {
      const index = selectedDeviceIds.indexOf(event.target.id);
      selectedDeviceIds.splice(index, 1);
    }
    //Fetch location for selected devices
    fetchLoactions(selectedDeviceIds)
    console.log("SelectedIds", selectedDeviceIds);
  }; 

  return (
    <div id="send-money" className="d-flex flex-column w-100">
      <Card className="rounded-gp border-0 bg-light p-md-3">
        <Card.Body>
        {
          devicesData.map((device, i) => {
            return <Form.Check type="checkbox" 
                      // checked={selectedDeviceIds.includes(device.deviceId)}
                      defaultChecked={true}
                      onClick={handleDeviceSelection}
                      id={device.deviceId}
                      label={device.deviceName} />
          })
         }
          <Map locations={JSON.parse(JSON.stringify(locations))} zoomLevel={10} />
        </Card.Body>
      </Card>
    </div>
  );
};

export { Devices };
