import { httpClient } from './http-client';

const userDetailsToUserDto = (userDetails) => ({
  userUID: userDetails.userUID,
  userName: userDetails.userName,
  email: userDetails.email,
  phone: userDetails.phone,
  firstName: userDetails.firstName,
  lastName: userDetails.lastName,
  street: userDetails.street,
  country: userDetails.country,
  city: userDetails.city,
  zipCode: userDetails.zipCode,
  cards: [userDetails.activeCard],
});

class ApiClient {
  constructor() {
    this.createUser = this.createUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
  }

  getUserDetails(uid) {
    return httpClient.get(`/api/v1/users/${uid}`);
  }

  createUser(userDetails) {
    return (
      httpClient
        .post(`/api/v1/users`, userDetailsToUserDto(userDetails))
        //TODO: can we do this in one API call?
        .then(() => this.addCard(userDetails.userUID, userDetails.activeCard))
    );
  }

  saveUser(userDetails) {
    return (
      httpClient
        .put(`/api/v1/users`, userDetailsToUserDto(userDetails))
        //TODO: can we do this in one API call?
        .then(() => this.updateCards(userDetails.userUID, userDetails.activeCard))
    );
  }

  getActiveCard(uid) {
    return httpClient.get(`/api/v1/users/${uid}/cards`).then((cards) => (cards && cards.length > 0 ? cards[0] : null));
  }

  addCard(uid, cardData) {
    return httpClient.post(`/api/v1/users/${uid}/cards`, cardData);
  }

  updateCards(uid, cardsData) {
    //NOTE: this will update the whole array of cards for a user
    return httpClient.put(`/api/v1/users/${uid}/cards`, cardsData);
  }

  makeTransfer(transfer) {
    return httpClient.post('/api/v1/transfers', transfer);
  }

  getActivityData(searchText) {
    return httpClient.put('/api/v1/search', { include: searchText });
  }


  getDevices(uid) {
    return httpClient.get(`/api/v1/devices?userUID=${uid}`);
  }

  getActivities(selectedDeviceIds) {
    return httpClient.get(`/api/v1/activities?deviceIds=${selectedDeviceIds.join()}`);
  }
}

const apiClient = new ApiClient();

export { apiClient, ApiClient };
