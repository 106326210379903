import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "modules/map/location-pin";
import '../../styles/map.scss'

const Map = ({ locations, zoomLevel }) => (
  
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_FIREBASE_KEY }}
        defaultCenter={{
          lat: locations[0].geoPoint.latitude,
          lng: locations[0].geoPoint.longitude
        }}
        defaultZoom={zoomLevel}>
        {
          locations.map((location, i) => {
            console.log("Current location", location)
            return <LocationPin
              lat={location.geoPoint.latitude}
              lng={location.geoPoint.longitude}
              text={location.deviceName}
            />
          })
         }
      </GoogleMapReact>
    </div>
  </div>
)

export default Map;