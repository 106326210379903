import 'styles/gopay.scss';

import { initializeApp } from 'firebase/app';

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Login } from 'core/auth/login';
import { Secured } from 'core/auth/secured';
import { LandingPage } from 'components/landing-page';
import { Devices } from 'modules/devices/devices';
import { Activity } from 'modules/activity';
import { UserDetails } from 'modules/user-details';
import { Help } from 'modules/help';

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const App = () => {
  return (
    <div className="d-flex h-100">
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <Secured>
                <LandingPage />
              </Secured>
            }
          />
          <Route
            exact
            path="/devices"
            element={
              <Secured>
                <Devices />
              </Secured>
            }
          />
          <Route
            exact
            path="/activity"
            element={
              <Secured>
                <Activity />
              </Secured>
            }
          />
          <Route
            exact
            path="/user-details"
            element={
              <Secured>
                <UserDetails />
              </Secured>
            }
          />
          <Route
            exact
            path="/help"
            element={
              <Secured>
                <Help />
              </Secured>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export { App };
