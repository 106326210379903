import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FiLogOut, FiUser } from 'react-icons/fi';

import { SecuredContext } from 'core/auth/secured';

const ActiveUser = ({ onLogOut }) => {
  const { userDetails } = React.useContext(SecuredContext);
  const userName = userDetails.displayName || userDetails.phoneNumber;
  // const openUserDetails = () => {};
  return (
    <div className="fw-bold d-flex flex-row align-items-center">
      <div className="h4 p-2 text-light bg-gp-gradient mb-0 me-2 rounded-circle d-flex flex-row align-items-center justify-content-center">
        <FiUser />
      </div>
      <NavDropdown title={userName}>
        <NavDropdown.Item href="/user-details">Account</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={onLogOut}>
          <span>Logout</span>
          <FiLogOut className="ms-2" />
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

export { ActiveUser };
