import React from 'react';
// import { Spinner } from 'react-bootstrap';

import { ReactComponent as GopayLogo } from 'assets/gopay-logo.svg';

const AppLoader = () => (
  <div id="app-loader" className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
    <GopayLogo className={`gopay-logo mb-5`} />
    {/* <Spinner animation="border" role="status" className="text-primary gp-icon flex-shrink-0" /> */}
  </div>
);

export { AppLoader };
