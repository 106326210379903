import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Card, Modal, Spinner } from 'react-bootstrap';
import { VscCheck, VscError } from 'react-icons/vsc';

import { SecuredContext } from 'core/auth/secured';
import { apiClient } from 'core/api/api-client';
import { MyIntlTelInput } from 'components/my-intl-tel-input';

const isNewUser = (userDetails) => !(userDetails.activeCard && userDetails.firstName && userDetails.lastName);

//TODO: harden this validation (card number length...)
const isValidCardData = (cardData) =>
  !!cardData.number &&
  !!cardData.nameOnCard &&
  !!cardData.expirationMonth &&
  !!cardData.expirationYear &&
  !!cardData.cvv;

const UserDetails = () => {
  const securedContext = React.useContext(SecuredContext);
  const [userDetails, setUserDetails] = useState(securedContext.userDetails);
  const [cardData, setCardData] = useState(userDetails.activeCard ? { ...userDetails.activeCard } : {});
  const [userDetailsState, setUserDetailsState] = useState(
    isNewUser(securedContext.userDetails) ? 'new-user' : 'clean'
  );
  const [errorCode, setErrorCode] = useState();

  const isModalVisible = ['saving', 'saved', 'failed'].includes(userDetailsState);
  let title;
  let overlay;
  switch (userDetailsState) {
    case 'saving':
      title = 'Saving...';
      overlay = (
        <>
          <Spinner animation="border" role="status" className="text-primary gp-icon flex-shrink-0">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
          <div className="mt-2 text-center">Please wait</div>
        </>
      );
      break;
    case 'saved':
      title = 'Saved!';
      overlay = (
        <>
          <VscCheck className="text-success gp-icon flex-shrink-0" />
          <div className="mt-2 text-center">Your user details have been updated</div>
        </>
      );
      break;
    case 'failed':
      title = 'Oops!';
      overlay = (
        <>
          {' '}
          <VscError className="text-danger gp-icon flex-shrink-0" />
          <div className="text-center">
            <p className="my-2">Your user details could not be updated,</p>
            <p>
              click <Link to={`/help#errorCodes${errorCode ? `-${errorCode}` : ''}`}>here</Link> for help
            </p>
          </div>
        </>
      );
      break;
    default:
  }

  const handleCardDataChange = (updatedCardData) => {
    setCardData(updatedCardData);
    setUserDetails({ ...userDetails, activeCard: updatedCardData });
    isValidCardData(updatedCardData) ? setUserDetailsState('dirty') : setUserDetailsState('clean');
  };

  const handleUserDataChange = (userData) => {
    setUserDetails({ ...userDetails, ...userData });
    //TODO: validate user entered data (phone, email...)
    setUserDetailsState('dirty');
  };


  const updatePhone = (isValid, value, selectedCountryData, fullNumber, extension) => {
    handleUserDataChange({ phoneNumber: fullNumber })
  };

  const save = () => {
    setUserDetailsState('saving');
    const saveFunction = isNewUser(securedContext.userDetails) ? apiClient.createUser : apiClient.saveUser;
    console.log(saveFunction);
    console.log(userDetails);
    saveFunction(userDetails)
      .then(() => {
        setUserDetailsState('saved');
        securedContext.setUserDetails(userDetails);
      })
      .catch((error) => {
        setErrorCode(error.code);
        setUserDetailsState('failed');
        console.log(error);
      });
  };

  return (
    <div id="user-details">
      <Form onSubmit={save} className="d-flex flex-column flex-grow-1 text-center">
        <Card className="rounded-gp border-0 d-flex flex-grow-1 bg-light px-md-3 mb-3">
          <Card.Header className="bg-transparent border-0 d-none d-md-block">
            <span className="display-5">User Details</span>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-12 col-md-6 offset-md-3">
                <div className="text-start mb-4">
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold small">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      size="sm"
                      value={userDetails.firstName}
                      onChange={(e) => handleUserDataChange({ firstName: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold small">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      size="sm"
                      value={userDetails.lastName}
                      onChange={(e) => handleUserDataChange({ lastName: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold small">Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      size="sm"
                      value={userDetails.email}
                      onChange={(e) => handleUserDataChange({ email: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold small">Phone</Form.Label>
                    <MyIntlTelInput
                     onPhoneChangeCallBack={updatePhone}
                     value={userDetails.phoneNumber}
                     />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold small">Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      size="sm"
                      value={userDetails.street}
                      onChange={(e) => handleUserDataChange({ street: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-0">
                    <Form.Label className="fw-bold small">Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      size="sm"
                      value={userDetails.country}
                      onChange={(e) => handleUserDataChange({ country: e.target.value })}
                    />
                  </Form.Group>
                  {/* <FloatingLabel label="UserName" className="mb-3" controlId="userName">
            <Form.Control type="text" placeholder="Username" size="sm" />
          </FloatingLabel> */}
                  {/* 
          <Form.Floating className="mb-3" controlId="userName">
            <Form.Label>UserName</Form.Label>
            <Form.Control type="text" placeholder="Username" size="sm" />
          </Form.Floating> */}
                </div>
              </div>
            </div>
            <Button
              className="px-5 mt-3 rounded-pill text-white"
              disabled={userDetailsState !== 'dirty'}
              onClick={save}>
              Save
            </Button>
          </Card.Body>
        </Card>
      </Form>
      <Modal id="card-saving-modal" show={isModalVisible} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">{overlay}</div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="px-4 mt-3 rounded-pill text-white"
            onClick={() =>
              userDetailsState === 'saved' ? setUserDetailsState('clean') : setUserDetailsState('dirty')
            }>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal id="new-user-details" show={userDetailsState === 'new-user'} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hello New User!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please setup your user account here to be able to send and receive money</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="px-4 mt-3 rounded-pill text-white" onClick={() => setUserDetailsState('clean')}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { UserDetails, isNewUser };
